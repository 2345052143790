<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :data-type="context.type"
  >
    <autocomplete
      baseClass="text"
      v-bind="context.attributes"
      :debounceTime="500"
      :value="context.model"
      :search="search"
      :get-result-value="getResultValue"
      @input="handleInput"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
export default {
  name: 'VueFormulateAutocomplete',

  props: {
    context: {
      type: Object,
      required: true,
    },

    search: {
      type: Function,
      required: true,
    },
  },

  computed: {
    config() {
      return this.$store.state.config;
    },
  },

  methods: {
    getResultValue(result) {
      let value = '';

      this.config.autocomplete.forEach((instruction) => {
        switch (instruction.type) {
          case 'field':
            value += `${result.fields[instruction.value]} `;
            break;

          default:
            value += `${instruction.value} `;
        }
      });

      return value;
    },

    handleInput(event) {
      this.context.model = event.target.value;
    },

    handleSubmit(value) {
      this.context.rootEmit('submit', value);
    },
  },
};
</script>
