<template>
  <div id="content">
    <div class="w-full">
      <img id="logo" src="../assets/logo.svg" />

      <template v-if="title.reports">
        <h1 id="heading">{{ title.reports }}</h1>

        <div id="subheading">or
          <router-link class="link" to="/">back to home.</router-link>
        </div>

        <div id="card">
          <FormulateForm :schema="schema" v-model="fields" @submit="createReport" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { Parser } from 'json2csv';

export default {
  name: 'Reports',

  data() {
    return {
      fields: {},
      disabled: false,
      index: null,
    };
  },

  computed: {
    formID() {
      return this.$store.state.formID;
    },

    algoliaKey() {
      return this.$store.state.algoliaKey;
    },

    schema() {
      const schema = this.$store.state.schema;

      schema.forEach((field) => {
        switch (field.type) {
          case 'autocomplete':
            field.search = this.search;
            field['@submit'] = this.autofill;
            break;

          case 'submit':
            field.disabled = this.disabled;
            break;

          default:
        }

        field.validation = '';
      });

      return [
        {
          labelClass: 'text-label',
          inputClass: 'text-input',
          errorClass: 'text-error',
          type: 'date',
          name: 'startDate',
          label: 'Start Date',
          placeholder: 'YYYY-MM-DD',
          validation: 'required|date:YYYY-MM-DD',
          validationName: 'Start Date',
        },
        {
          class: 'mt-3',
          labelClass: 'text-label',
          inputClass: 'text-input',
          errorClass: 'text-error',
          type: 'date',
          name: 'endDate',
          label: 'End Date',
          placeholder: 'YYYY-MM-DD',
          validation: 'required|date:YYYY-MM-DD',
          validationName: 'End Date',
        },
        ...schema,
      ];
    },

    title() {
      return this.$store.state.title;
    },

    config() {
      return this.$store.state.config;
    },
  },

  watch: {
    algoliaKey: {
      immediate: true,
      handler(value) {
        const client = algoliasearch(
          process.env.VUE_APP_ALGOLIA_ID,
          value,
        );

        this.index = client.initIndex(process.env.VUE_APP_ALGOLIA_INDEX);
      },
    },
  },

  methods: {
    async search(value) {
      try {
        const results = await this.index.search(value, {
          distinct: true,
        });

        return results.hits;
      } catch (error) {
        alert(error.message);
        return [];
      }
    },

    autofill(value) {
      this.config.autofill.forEach((field) => {
        this.fields[field] = value.fields[field];
      });
    },

    downloadReport(results) {
      const parser = new Parser();

      results.forEach((result) => {
        Object.keys(result.fields).forEach((key) => {
          this.schema.forEach((field) => {
            if (field.name === key) {
              if (Array.isArray(result.fields[key])) {
                result.fields[key].forEach((value, index) => {
                  result.fields[key][index] = field.options[value];
                });

                result.fields[key] = result.fields[key].join(', ');
              }

              result.fields[field.label] = result.fields[key];
              delete result.fields[key];
            }
          });
        });
      });

      const csv = parser.parse(results.map((result) => result.fields));
      const uri = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      const link = document.createElement('a');

      link.setAttribute('href', uri);
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);

      link.click();
    },

    async createReport() {
      try {
        this.disabled = true;

        let filters = `timestamp>=${new Date(this.fields.startDate).getTime()} AND timestamp<${new Date(this.fields.endDate).getTime() + 86400000}`;

        Object.keys(this.fields).forEach((key) => {
          if (this.fields[key].length > 0 && key !== 'startDate' && key !== 'endDate') {
            if (Array.isArray(this.fields[key])) {
              this.fields[key].forEach((value, index) => {
                if (index === 0 && this.fields[key].length > 1) {
                  filters += ` AND (fields.${key}:${value}`;
                } else if (index === 0) {
                  filters += ` AND fields.${key}:${value}`;
                } else if (index === this.fields[key].length - 1) {
                  filters += ` OR fields.${key}:${value})`;
                } else {
                  filters += ` OR fields.${key}:${value}`;
                }
              });
            } else {
              filters += ` AND fields.${key}:${this.fields[key]}`;
            }
          }
        });

        const results = await this.index.search('', {
          filters: filters,
          hitsPerPage: 1000,
        });

        const distinctResults = await this.index.search('', {
          filters: filters,
          distinct: true,
          hitsPerPage: 1000,
        });

        let resultsCount = results.nbHits;
        const distinctResultsCount = distinctResults.nbHits;

        this.config.modifiers.forEach((modifier) => {
          results.hits.forEach((result) => {
            if (result.fields[modifier.field]) {
              switch (modifier.type) {
                case 'addition':
                  resultsCount += Number(result.fields[modifier.field]);
                  break;

                default:
              }
            }
          });
        });

        this.disabled = false;
        this.downloadReport(results.hits);

        alert(
          `${this.title.results}: ${resultsCount}\n `
          + `${this.title.distinctResults}: ${distinctResultsCount}\n `
          + 'Downloading now!',
        );
      } catch (error) {
        this.disabled = false;
        alert(error.message);
      }
    },
  },
};
</script>
