import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    formID: '',
    algoliaKey: '',
    schema: [],
    admins: [],
    title: {},
    config: {},
  },

  mutations: {
    updateState(state, payload) {
      state[payload.key] = payload.value;
    },
  },

  actions: {},
  modules: {},
});
