<template>
  <div id="content">
    <div class="w-full">
      <img id="logo" src="../assets/logo.svg" />

      <template v-if="title.search">
        <h1 id="heading">{{ title.search }}</h1>

        <div id="subheading">or
          <router-link class="link" to="/">back to home.</router-link>
        </div>

        <div id="card" v-if="submissions.length > 0">
          <ul class="list">
            <li class="mt-4" v-for="label in snapshot" :key="label" v-html="label" />
          </ul>
        </div>

        <div id="card">
          <FormulateForm v-model="fields">
            <FormulateInput
              baseClass="text"
              type="autocomplete"
              name="search"
              :placeholder="title.search"
              :search="search"
              @submit="getSubmissions"
            />

            <ul class="list">
              <li class="mt-4" v-for="submission in submissions" :key="submission.id">
                <router-link class="link" :to="submission.route">
                  {{ submission.label }}
                </router-link>
              </li>
            </ul>
          </FormulateForm>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';

export default {
  name: 'Search',

  data() {
    return {
      fields: {},
      submissions: [],
      snapshot: [],
      index: null,
    };
  },

  computed: {
    algoliaKey() {
      return this.$store.state.algoliaKey;
    },

    title() {
      return this.$store.state.title;
    },

    config() {
      return this.$store.state.config;
    },

    schema() {
      return this.$store.state.schema;
    },
  },

  watch: {
    algoliaKey: {
      immediate: true,
      handler(value) {
        const client = algoliasearch(
          process.env.VUE_APP_ALGOLIA_ID,
          value,
        );

        this.index = client.initIndex(process.env.VUE_APP_ALGOLIA_INDEX);
      },
    },
  },

  methods: {
    async search(value) {
      try {
        const results = await this.index.search(value, {
          distinct: true,
        });

        return results.hits;
      } catch (error) {
        alert(error.message);
        return [];
      }
    },

    autofill(value) {
      let search = '';

      this.config.autocomplete.forEach((instruction) => {
        switch (instruction.type) {
          case 'field':
            search += `${value.fields[instruction.value]} `;
            break;

          default:
            search += `${instruction.value} `;
        }
      });

      this.fields.search = search;
    },

    getSnapshot(result) {
      const snapshot = [];

      this.config.snapshot.forEach((name) => {
        this.schema.forEach((field) => {
          if (field.name === name) {
            snapshot.push(`<b>${field.label}: </b> ${result.fields[name] || 'N/A'}`);
          }
        });
      });

      this.snapshot = snapshot;
    },

    async getSubmissions(value) {
      try {
        this.submissions = [];

        let key = '';

        this.config.key.forEach((field) => {
          key += value.fields[field].toUpperCase();
        });

        const results = await this.index.search('', {
          filters: `key:${key.replace(/\s/g, '')}`,
          length: 1000,
        });

        results.hits.forEach((result) => {
          let label = '';

          this.config.submission.forEach((instruction) => {
            switch (instruction.type) {
              case 'field':
                label += `${result.fields[instruction.value]} `;
                break;

              default:
                label += `${instruction.value} `;
            }
          });

          this.submissions.push({
            id: result.objectID,
            label: label,
            route: `/submissions/${result.objectID}`,
          });
        });

        this.autofill(value);
        this.getSnapshot(results.hits[0]);
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>
