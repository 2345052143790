<template>
  <div id="content">
    <div class="w-full">
      <img id="logo" src="../assets/logo.svg" />

      <template v-if="title.main">
        <h1 id="heading">{{ title.main }}</h1>

        <div id="subheading">or
          <a class="link" @click="signOut">sign out of Engage DB.</a>
        </div>

        <div id="card">
          <ul class="list">
            <li v-if="isAdmin">
              <router-link class="link" to="/new">{{ title.new }}</router-link>
            </li>

            <li class="my-4">
              <router-link class="link" to="/search">{{ title.search }}</router-link>
            </li>

            <li v-if="isAdmin">
              <router-link class="link" to="/reports">{{ title.reports }}</router-link>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { auth, db, functions } from '../firebase';

export default {
  name: 'Home',

  computed: {
    title() {
      return this.$store.state.title;
    },

    isAdmin() {
      if (this.$store.state.admins) {
        return this.$store.state.admins.includes(auth.currentUser.uid);
      }

      return false;
    },
  },

  async created() {
    try {
      const forms = await db
        .collection('forms')
        .where('owners', 'array-contains', auth.currentUser.uid)
        .get();

      const getAlgoliaKey = functions.httpsCallable('getAlgoliaKey');

      this.$store.commit('updateState', {
        key: 'formID',
        value: forms.docs[0].id,
      });

      this.$store.commit('updateState', {
        key: 'algoliaKey',
        value: (await getAlgoliaKey({ formID: forms.docs[0].id })).data,
      });

      this.$store.commit('updateState', {
        key: 'schema',
        value: JSON.parse(forms.docs[0].data().schema),
      });

      this.$store.commit('updateState', {
        key: 'admins',
        value: forms.docs[0].data().admins,
      });

      this.$store.commit('updateState', {
        key: 'title',
        value: forms.docs[0].data().title,
      });

      this.$store.commit('updateState', {
        key: 'config',
        value: forms.docs[0].data().config,
      });
    } catch (error) {
      alert(error.message);
    }
  },

  methods: {
    async signOut() {
      try {
        await auth.signOut();

        this.$router.push({ path: '/sign-in' });
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
</script>
