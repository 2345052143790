<template>
  <div id="content">
    <div class="w-full">
      <img id="logo" src="../assets/logo.svg" />

      <template v-if="title.new">
        <h1 id="heading">{{ title.new }}</h1>

        <div id="subheading">or
          <router-link class="link" to="/">back to home.</router-link>
        </div>

        <div id="card">
          <FormulateForm :schema="schema" v-model="fields" @submit="createSubmission" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { db } from '../firebase';

export default {
  name: 'New',

  data() {
    return {
      fields: {},
      disabled: false,
      index: null,
    };
  },

  computed: {
    formID() {
      return this.$store.state.formID;
    },

    algoliaKey() {
      return this.$store.state.algoliaKey;
    },

    schema() {
      const schema = this.$store.state.schema;

      schema.forEach((field) => {
        switch (field.type) {
          case 'autocomplete':
            field.search = this.search;
            field['@submit'] = this.autofill;
            break;

          case 'submit':
            field.disabled = this.disabled;
            break;

          default:
        }
      });

      return schema;
    },

    title() {
      return this.$store.state.title;
    },

    config() {
      return this.$store.state.config;
    },
  },

  watch: {
    algoliaKey: {
      immediate: true,
      handler(value) {
        const client = algoliasearch(
          process.env.VUE_APP_ALGOLIA_ID,
          value,
        );

        this.index = client.initIndex(process.env.VUE_APP_ALGOLIA_INDEX);
      },
    },
  },

  methods: {
    async search(value) {
      try {
        const results = await this.index.search(value, {
          distinct: true,
        });

        return results.hits;
      } catch (error) {
        alert(error.message);
        return [];
      }
    },

    autofill(value) {
      const fields = {};

      this.config.autofill.forEach((field) => {
        fields[field] = value.fields[field];
      });

      this.fields = fields;
    },

    async createSubmission() {
      try {
        this.disabled = true;

        Object.keys(this.fields).forEach((key) => {
          if (this.fields[key] === '' || this.fields[key].length === 0) {
            delete this.fields[key];
          }
        });

        await db
          .collection('forms')
          .doc(this.formID)
          .collection('submissions')
          .add({
            fields: this.fields,
            timestamp: this.config.timestamp
              ? new Date(this.fields[this.config.timestamp])
              : new Date(),
          });

        alert(`${this.title.new} added!`);
        window.location.reload();
      } catch (error) {
        this.disabled = false;
        alert(error.message);
      }
    },
  },
};
</script>
