import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import VueFormulateAutocomplete from './components/vue-formulate-autocomplete.vue';

Vue.component('VueFormulateAutocomplete', VueFormulateAutocomplete);

Vue.use(VueFormulate, {
  library: {
    autocomplete: {
      component: 'VueFormulateAutocomplete',
      classification: 'text',
      slotProps: {
        component: ['baseClass', 'search'],
      },
    },
  },
});
