import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/home.vue';
import SignIn from './views/sign-in.vue';
import New from './views/new.vue';
import Search from './views/search.vue';
import Submissions from './views/submissions.vue';
import Reports from './views/reports.vue';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Home,
      meta: { requiresAuth: true },
    },
    {
      path: '/sign-in',
      component: SignIn,
    },
    {
      path: '/new',
      component: New,
      meta: { requiresAuth: true },
    },
    {
      path: '/search',
      component: Search,
      meta: { requiresAuth: true },
    },
    {
      path: '/submissions/:submissionID',
      component: Submissions,
      meta: { requiresAuth: true },
    },
    {
      path: '/reports',
      component: Reports,
      meta: { requiresAuth: true },
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
