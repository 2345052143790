<template>
  <div id="content">
    <div class="w-full">
      <img id="logo" src="../assets/logo.svg" />

      <h1 id="heading">Sign in to Engage DB</h1>

      <div id="subheading">or
        <a class="link" href="mailto:Jacob@engagemsp.com">reset your password.</a>
      </div>

      <div id="card">
        <FormulateForm v-model="fields" @submit="signIn">
          <FormulateInput
            labelClass="text-label"
            inputClass="text-input"
            errorClass="text-error"
            type="email"
            name="email"
            label="Email"
            validation="required|email"
            validationName="Email"
          />

          <FormulateInput
            class="mt-3"
            labelClass="text-label"
            inputClass="text-input"
            errorClass="text-error"
            type="password"
            name="password"
            label="Password"
            validation="required"
            validationName="Password"
          />

          <FormulateInput
            class="button"
            type="submit"
            label="Sign In"
            :disabled="disabled"
          />
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '../firebase';

export default {
  name: 'SignIn',

  data() {
    return {
      fields: {},
      disabled: false,
    };
  },

  methods: {
    async signIn() {
      try {
        this.disabled = true;

        await auth.signInWithEmailAndPassword(
          this.fields.email,
          this.fields.password,
        );

        this.$router.push({ path: '/' });
      } catch (error) {
        this.disabled = false;
        alert(error.message);
      }
    },
  },
};
</script>
