import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import { auth } from './firebase';
import './vue-formulate';
import './autocomplete';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        next();
      } else {
        next({ path: '/sign-in' });
      }

      unsubscribe();
    });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
