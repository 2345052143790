<template>
  <div id="content">
    <div class="w-full">
      <img id="logo" src="../assets/logo.svg" />

      <template v-if="title">
        <h1 id="heading">{{ title }}</h1>

        <div id="subheading">or
          <router-link class="link" to="/search">back to search.</router-link>
        </div>

        <div id="card">
          <FormulateForm :schema="schema" v-model="fields" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { db } from '../firebase';

export default {
  name: 'Submissions',

  data() {
    return {
      title: '',
      fields: {},
    };
  },

  computed: {
    formID() {
      return this.$store.state.formID;
    },

    submissionID() {
      return this.$route.params.submissionID;
    },

    schema() {
      const schema = this.$store.state.schema;

      schema.forEach((field, index) => {
        switch (field.type) {
          case 'autocomplete':
            field.search = () => { };
            break;

          case 'submit':
            schema.splice(index, 1);
            break;

          default:
        }

        field.disabled = true;
      });

      return schema;
    },

    config() {
      return this.$store.state.config;
    },
  },

  async created() {
    try {
      const form = await db
        .collection('forms')
        .doc(this.formID)
        .collection('submissions')
        .doc(this.submissionID)
        .get();

      let title = '';

      this.config.submission.forEach((instruction) => {
        switch (instruction.type) {
          case 'field':
            title += `${form.data().fields[instruction.value]} `;
            break;

          default:
            title += `${instruction.value} `;
        }
      });

      this.fields = form.data().fields;
      this.title = title;
    } catch (error) {
      alert(error.message);
    }
  },
};
</script>
